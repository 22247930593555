import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Title2, Title3, Large, colors, Button } from 'js-components';
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper';
import PercentLinks from '../../components/PercentLinks';
import { Link as GatsbyLink } from 'gatsby';
import { Helmet } from 'react-helmet';
import { PageTitle, PageTitle2, PageTitle3, PageParagraph } from '../../components/PageTitle';
import { keyframes } from 'styled-components'

import ConversationCloud from '../images/conversationCloudDiagram.svg';

import LayerConnector from '../images/LayerConnector.svg';
import LayerGlobal from '../images/layerGlobal.svg';
import LayerTelephony from '../images/layerTelephony.svg';
import LayerWorkflow from '../images/layerWorkflow.svg';
import RobotGrid from '../images/grid6.png';
import Quote from '../images/jackieL.png';
import Radials from '../images/radials.svg';
import NopeIcon from '../images/nopeIcon.svg';
import Check from '../images/check.svg';
import ActionEvent from '../images/actionEvent.svg';
import { CallToAction, GetStarted } from '../../components/CallToAction'

import { FeatureGrid, FeatureIcon, FeatureTile} from '../../components/FeatureGrid';
import Bot1 from '../images/bot1.svg';
import Bot2 from '../images/bot2.svg';
import Bot3 from '../images/bot3.svg';

import Default from '../../components/Layouts/Default';


export default class RevenueProcessAutomation extends Component {

  render() {
    return (
      <Default>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Truly | Scale Coaching Like Never Before</title>
          <meta
            name="description"
            content="More training.  More Visibility.  Fraction of the cost."
          />
        </Helmet>

        <WrapperSolid color="#222222">
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
          <Box width={[1, 2 / 3]}>
            <SolutionsPageSection>
                <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" left>
                Guide sellers to next steps
                </PageTitle2>
                <PageTitle left color={colors.white} mb="1.5rem">
                Amplify Your Coaching With Automated Feedback Loops
                </PageTitle>
                <a href="https://hello.truly.co/book-a-meeting">
                  <SignUpButton>Request A Demo</SignUpButton>
                </a>
              </SolutionsPageSection>
            </Box>
            <Box width={[1, 1 / 3]}>
              <TestimonialSection>
                <TestimonialRadialImg src={Radials} />;
                <TestimonalTile>
                  <TestimonialImg src={Quote} />
                  <TestimonialQuote>
                  "One of the most revolutionary ideas in RevOps today."
                  </TestimonialQuote>
                  <TestimonialAttribution>Jacki L</TestimonialAttribution>
                  <TestimonialTitle>Winning By Design</TestimonialTitle>
                </TestimonalTile>
              </TestimonialSection>
            </Box>
          </Section>
        </WrapperSolid>

        <WrapperSolid color="#F4F4F4">
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <PageTitle2 left color={colors.trulyDark} mb="1.5rem">
            Micromanagement is neither liked, nor effective.<br/>Robots can make it history.
            </PageTitle2>

            <BeforeAfterContainer>
              <BeforeAfterLeft>
                <BeforeAfterSection>
                  <BeforeAfterTitle><BeforeAfterPointIcon src={NopeIcon}/>Old Workflow</BeforeAfterTitle>
                    <BeforeAfterPoint>Managers spend hours trying<br/>to find coachable moments</BeforeAfterPoint>
                    <BeforeAfterPoint>Coaching feedback is squeezed<br/>into the end of packed 1:1’s</BeforeAfterPoint>
                </BeforeAfterSection>
                <BeforeAfterSpacer />
                <BeforeAfterSection>
                  <BeforeAfterTitle><BeforeAfterPointIcon src={NopeIcon}/>Old Result</BeforeAfterTitle>
                    <BeforeAfterPoint>Tired Managers</BeforeAfterPoint>
                    <BeforeAfterPoint>Frustrated reps who are behind on quota</BeforeAfterPoint>
                </BeforeAfterSection>
              </BeforeAfterLeft>
              <BeforeAfterRight>
                <BeforeAfterSectionTruly>
                  <BeforeAfterIcon src={Check}/>
                  <BeforeAfterTitleTruly>With Robots</BeforeAfterTitleTruly>
                    <BeforeAfterPointTruly>Robots passively monitor all activity and model deal and contact level engagement.</BeforeAfterPointTruly>
                    <BeforeAfterPointTruly>They then apply your health checks to find issues and trigger the right processes automatically.</BeforeAfterPointTruly>
                </BeforeAfterSectionTruly>
              </BeforeAfterRight>
            </BeforeAfterContainer>
          </Section>
        </WrapperSolid>

        <WrapperSolid color="white">
          <Section flexDirection="row">
            <PageTitle2 left color={colors.trulyDark} mb="1.5rem">
            What can a robot do?
            </PageTitle2>
            <FeatureGrid>

              <FeatureTile>
                <GatsbyLink to="/solutions/automate-linkedin-prospecting">
                <FeatureIcon src={Bot1} />
                <PageTitle3 color={colors.trulyDark}>Score Activities</PageTitle3>
                <PageParagraph color={colors.trulyDark}>Make sure reps are following key parts of your scripts/playbooks and give them feedback on every touchpoint (call, meeting, email, text & more).</PageParagraph>
                </GatsbyLink>
              </FeatureTile>

              <FeatureTile>
                <GatsbyLink to="/solutions/better-than-a-contact-data-provider">
                <FeatureIcon src={Bot2} />
                <PageTitle3 color={colors.trulyDark}>Guide Reps</PageTitle3>
                <PageParagraph color={colors.trulyDark}>Push best next step recommendations to reps once deal issues are detected. </PageParagraph>
                </GatsbyLink>
              </FeatureTile>


              <FeatureTile>
                <GatsbyLink to="/solutions/automate-lifecycle-marketing">
                <FeatureIcon src={Bot3} />
                <PageTitle3 color={colors.trulyDark}>Buid</PageTitle3>
                <PageParagraph color={colors.trulyDark}>Captures the entire contents of a given contact's LinkedIn profile</PageParagraph>
                </GatsbyLink>
              </FeatureTile>
            </FeatureGrid>

            <GatsbyLink to="/features/advanced-text-parsing">
              <SignUpButton>See All Features</SignUpButton>
            </GatsbyLink>

          </Section>
        </WrapperSolid>

        <WrapperBackground background="revenueRobots" gradientType="lightBlue">
          <Section flexDirection="column" py={['6rem', '3rem']} background={colors.trulyDark}>
              <PageTitle2 as="h2" color={colors.trulyDark} bold my="1.5rem">
              How Much More Effective Are Robots Exactly?
              </PageTitle2>
              <Large as="p" color={colors.trulyDark} mb="1.5rem" textAlign="left" >
              The numbers don’t lie.  Robots are significantly better positioned to execute your prospecting tasks than humans 
              </Large>
              <WidthImg src={RobotGrid}/>
          </Section>
        </WrapperBackground>

        <WrapperBackground background="sf" gradientType="black">
          <GetStarted/>
        </WrapperBackground>
      </Default>
    );
  }
}

const BeforeAfterContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;

@media (max-width: 1024px) {
  flex-direction: column
}
`


const BeforeAfterLeft = styled.div`
width: 50%;
padding: 8px;
@media (max-width: 1024px) {
  width: 100%;
}
`

const BeforeAfterSpacer = styled.div`
height: 16px;
`

const BeforeAfterRight = styled.div`
width: 50%;
padding: 8px;
@media (max-width: 1024px) {
  width: 100%;
}
`

const BeforeAfterSection = styled.div`
background-color: white;;
border: #D8D8D8 1px solid;
width: 100%;
border-radius: 16px;
overflow: hidden;
box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
`

const BeforeAfterSectionTruly = styled.div`
background: #D6FFD7;
border: 2.20122px solid #85BF86;
height: 100%;
width: 100%;
border-radius: 16px;
overflow: none;
box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
display: flex;
flex-direction: column;
justify-content: center;
`

const BeforeAfterTitle = styled.div`
height: 48px;
background-color: #F4F4F4;
color: #D11313;
font-size: 19px;
border-bottom: #D8D8D8 1px solid;
font-weight: bold;
line-height: 48px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`

const BeforeAfterPoint = styled.div`
height: 64px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
color: #454A51;
font-size: 16px;
border-bottom: #D8D8D8 1px solid;
font-weight: bold;
padding: 0 16px;
`

const BeforeAfterPointIcon  = styled.img`
width: 22px;
height: 22px;
margin-right: 10px;
margin-bottom: 4px;
`

const BeforeAfterTitleTruly = styled.div`
color: #1F331F;
font-size: 32px;
font-weight: bold;
line-height: 48px;
`

const BeforeAfterPointTruly = styled.div`
line-height: 26px;
color: #1F331F;
font-size: 19px;
font-weight: regular;
padding: 16px 32px;
`

const TestimonalTile = styled.div`
  width: 300px;
  padding: 0 16px;
  margin: 32px;
  color: ${colors.trulyDark};
  background-color: #CEF5FF;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  border-radius :15px;
`

function rotate() {
  const rotation = keyframes`
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  `;
  return rotation;
}

const TestimonialSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const TestimonialRadialImg = styled.img`
position: absolute;
z-index:-500;
opacity: 30%;
transform: translate(-50%,-50%);
animation: ${rotate()} 100s;
animation-iteration-count: infinite;

@media (max-width: 1024px) {
  display: none;
}
`

const TestimonialImg = styled.img`
width: 64px;
height: 64px;
margin: 32px 12px;
`

const BeforeAfterIcon = styled.img`
width: 32px;
height: 32px;
margin: 16px auto 8px auto;
`

const TestimonialQuote = styled.div`
font-size: 16px;
font-style: italic;
`

const TestimonialAttribution = styled.div`
font-size: 13px;
font-wight: bold;
margin: 8px 0;
`

const TestimonialTitle = styled.div`
font-size: 12px;
font-wight: bold;
text-transform: capitalize;
margin-bottom: 32px;
`

const Values = styled.div`
  background-color: #222;
  font-family: 'Josefin Sans';
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
`;

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;

const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;

const SolutionsPageSection = styled.div`
  text-align: left;

  @media (max-width: 1024px) {
    text-align: center;
  }
`

const SignUpButton = styled.div`
background-color: #CEF5FF;
border-radius: 26px;
overflow: none;
width: 250px;
height: 52px;
text-align: center;
font-size: 19px;
font-weight: bold;
line-height: 52px;
transition: 0.3s all;
color: ${colors.trulyDark};

:hover{
  transform: scale(1.05);
  transition: 0.3s all;
}

@media (max-width: 1024px) {
  margin: 0 auto;
}
`